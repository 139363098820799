<template>
  <div class="d-lg-flex flex-column min-height-100">
    <div class="text-h6 font-weight-bold mb-4">
      Audience Reachability
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isThemeLight ? 'black' : null"
            v-bind="attrs"
            v-on="on"
          >
            help_outline
          </v-icon>
        </template>
        <span>
          Shows percentage of followers that follow more than 1500 accounts, between 1000-1500, 500-1000 and below 500. Accounts following more than 1.5k accounts will most likely not see the sponsored posts.
        </span>
      </v-tooltip>
    </div>

    <v-card class="border-radius-1em box-shadow-soft flex-grow-1">
      <v-card-text>
        <!-- render the chart component -->
        <apex-chart
          type="bar"
          :height="350"
          :options="chartOptions"
          :series="series"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// Import node packages
import ApexChart from 'vue-apexcharts'
import merge from 'lodash/merge'

// Import default chart configurations
import apexChartBaseOptions from '@/json/apexChartBaseOptions.js'

// Export the SFC
export default {
  // Name of the component
  name: "AudienceReachability",

  // Accept incoming data from parent
  props: {
    audienceData: {
      type: Object,
      required: true
    }
  },

  // Register the component
  components: {
    ApexChart
  },

  // Define computable readonly properties
  computed: {
    /**
     * Get the series data for chart
     *
     * @returns {Array}
     */
    series() {
      return [
        {
          data: this.audienceData.reachability
        }
      ]
    },

    /**
     * Get the chart configurations options
     *
     * @returns {Object}
     */
    chartOptions() {
      return merge(apexChartBaseOptions(), {
        colors: ["#2196F3"],
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          formatter(value) {
            return numeral.fPercent(value);
          },
          style: {
            colors: ["#f05d56"],
          },
          offsetY: -25,
        },
        yaxis: {
          type: "numeric",
          labels: {
            formatter: (val) => numeral.fPercent(val),
          },
        },
        tooltip: {
          enabled: false,
          marker: { show: false },
          y: {
            title: {
              formatter: (ser) => ``,
            },
            formatter: (ser) => numeral.fPercent(ser),
          },
        },
        plotOptions: {
          bar: {
            barHeight: "28%",
            horizontal: false,
            columnWidth: "28%",
            borderRadius: 4,
            dataLabels: {
              enabled: true,
              position: "top",
            },
          },
        },
        xaxis: {
          type: "category",
          title: {
            text: "Followings",
          },
        },
      })
    }
  }
}
</script>
